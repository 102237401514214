<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8">
      <div class="vx-row">
        <div class="vx-col w-full mb-3">
          <div class="float-right">
            <label class="text-sm opacity-75">PRIVACY</label>
            <vs-switch v-model="privacy" />
          </div>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Hospital Code</label>
          <v-select
            :options="hospitalCodeOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="hospitalCode"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Date</label>
          <v-select
            :options="dateOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="typeDate"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <small class="date-label">Start Date</small>
          <datepicker
            :language="$vs.rtl ? langHe : langEn"
            name="start-date"
            v-model="startDate"
            :disabled="typeDate.value != 'custom'"
            :disabledDates="disabledStartDatesFrom"
            :format="'dd/MM/yyyy'"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <small class="date-label">End Date</small>
          <datepicker
            :language="$vs.rtl ? langHe : langEn"
            :disabled="typeDate.value != 'custom'"
            :disabledDates="disabledEndDatesTo"
            name="end-date"
            v-model="endDate"
            :format="'dd/MM/yyyy'"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <!-- <small class="date-label"></small> -->
          <vs-button
            icon-pack="feather"
            icon="icon-edit"
            class="mt-6"
            @click="submitFilter(typeDate.value)"
          >Submit</vs-button>
        </div>
      </div>
    </vx-card>

        <div class="vx-row">
      <!-- COLUMN CHART -->
      <div class="vx-col md:w-1/1 w-full mb-base">
        <vx-card v-if="privacy" style="height:450px"></vx-card>
        <vx-card v-else title="Average Revenue by Physician">
          <!-- CARD ACTION -->
          <template slot="actions">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <small class="flex cursor-pointer">
                <span>{{filterAvgRevenueByPhysican}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1" />
              </small>
              <vs-dropdown-menu class="w-32">
                <vs-dropdown-item @click="getAvgRevenueByPhysican('last30Days')">Last 30 days</vs-dropdown-item>
                <vs-dropdown-item @click="getAvgRevenueByPhysican('thisMonth')">This month</vs-dropdown-item>
                <vs-dropdown-item @click="getAvgRevenueByPhysican('thisQuarter')">This quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getAvgRevenueByPhysican('thisYear')">This year</vs-dropdown-item>
                <vs-dropdown-item @click="getAvgRevenueByPhysican('lastMonth')">Last month</vs-dropdown-item>
                <vs-dropdown-item @click="getAvgRevenueByPhysican('lastQuarter')">Last quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getAvgRevenueByPhysican('lastYear')">Last year</vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>

          <vue-apex-charts
            v-if="avgRevenueByPhysican"
            type="line"
            height="350"
            :options="avgRevenueByPhysican.chartOptions"
            :series="avgRevenueByPhysican.series"
          ></vue-apex-charts>
        </vx-card>
      </div>
      
    </div>

    <div class="vx-row">
      <!-- COLUMN CHART -->
      <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card v-if="privacy" style="height:450px"></vx-card>
        <vx-card v-else title="Patient Encounter Breakdown">
          <!-- CARD ACTION -->
          <template slot="actions">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <small class="flex cursor-pointer">
                <span>{{filterPatientEncouterBreakdown}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1" />
              </small>
              <vs-dropdown-menu class="w-32">
                <vs-dropdown-item @click="getPatientEncouterBreakdown('last30Days')">Last 30 days</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdown('thisMonth')">This month</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdown('thisQuarter')">This quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdown('thisYear')">This year</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdown('lastMonth')">Last month</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdown('lastQuarter')">Last quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdown('lastYear')">Last year</vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>

          <vue-apex-charts
            v-if="patientEncouterBreakdown"
            type="bar"
            height="350"
            :options="patientEncouterBreakdown.chartOptions"
            :series="patientEncouterBreakdown.series"
          ></vue-apex-charts>
        </vx-card>
      </div>

      <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card v-if="privacy" style="height:450px"></vx-card>
        <vx-card v-else title="Revenue by Clinic" style="height:450px">
          <v-select
            multiple
            :closeOnSelect="false"
            v-model="hospitalCodeRevenueByClinic"
            :options="hospitalCodeOptions"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="mb-2"
          />

          <vs-dropdown vs-trigger-click class="cursor-pointer mb-2">
            <small class="flex cursor-pointer">
              <span>{{filterRevenueByClinic}}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1" />
            </small>
            <vs-dropdown-menu class="w-32">
              <vs-dropdown-item @click="getRevenueByClinic('last30Days')">Last 30 days</vs-dropdown-item>
              <vs-dropdown-item @click="getRevenueByClinic('thisMonth')">This month</vs-dropdown-item>
              <vs-dropdown-item @click="getRevenueByClinic('thisQuarter')">This quarter</vs-dropdown-item>
              <vs-dropdown-item @click="getRevenueByClinic('thisYear')">This year</vs-dropdown-item>
              <vs-dropdown-item @click="getRevenueByClinic('lastMonth')">Last month</vs-dropdown-item>
              <vs-dropdown-item @click="getRevenueByClinic('lastQuarter')">Last quarter</vs-dropdown-item>
              <vs-dropdown-item @click="getRevenueByClinic('lastYear')">Last year</vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <vue-apex-charts
            v-if="revenueByClinic"
            type="pie"
            height="250"
            :options="revenueByClinic.chartOptions"
            :series="revenueByClinic.series"
          ></vue-apex-charts>
        </vx-card>
      </div>

      <div class="vx-col md:w-1/1 w-full mb-base">
        <vx-card v-if="privacy" style="height:45px"></vx-card>
        <vx-card v-else title="Patient Payer Breakdown By Speciliaty">
          <!-- CARD ACTION -->
          <template slot="actions">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <small class="flex cursor-pointer">
                <span>{{filterPatientPayerBreakdownBySpeciliaty}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1" />
              </small>
              <vs-dropdown-menu class="w-32">
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('last30Days')"
                >Last 30 days</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('thisMonth')"
                >This month</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('thisQuarter')"
                >This quarter</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('thisYear')"
                >This year</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('lastMonth')"
                >Last month</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('lastQuarter')"
                >Last quarter</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('lastYear')"
                >Last year</vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>

          <vue-apex-charts
            v-if="patientPayerBreakdownBySpeciliaty"
            type="bar"
            height="350"
            :options="patientPayerBreakdownBySpeciliaty.chartOptions"
            :series="patientPayerBreakdownBySpeciliaty.series"
          ></vue-apex-charts>
        </vx-card>
      </div>

      
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import VueApexCharts from 'vue-apexcharts'

import vSelect from 'vue-select'

import Datepicker from 'vuejs-datepicker'
import { en, he } from 'vuejs-datepicker/src/locale'

//const themeColors = ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E']

//import apexChatData from '@/views/charts-and-maps/charts/apex-charts/apexChartData.js'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'

export default {
  components: {
    VueApexCharts,
    vSelect,
    Datepicker,
    ChangeTimeDurationDropdown
  },
  data () {
    return {
      privacy: false,


      hospitalCode: 'CLINIC1',
      hospitalCode2: 'CLINIC2',
      hospitalCodeOptions: [
        'CLINIC1',
'CLINIC2',
'CLINIC3',
'CLINIC4',
'CLINIC6',
'CLINIC7',
'CLINIC8',
'CLINIC9',
'WSGK1',
'ORGLOGIX1'
        
      ],
      startDate: new Date(),
      endDate: new Date(),
      langHe: he,
      langEn: en,

      specialitys: [
        'Obstetrics',
        'Beauty',
        'Nursery',
        'Dental',
        'Pediatrics & Neonatology',
        'Ophthalmology ',
        'Vaccinnate',
        'Education',
        'Consulting',

      ],

      endDay: 0,
      endMonth: 0,
      endYear: 0,

      startDay: 0,
      startMonth: 0,
      startYear: 0,

      lastEndTime: new Date(),
      lastStartTime: new Date(),
      thisEndTime: new Date(),
      thisStartTime: new Date(),

      typeDate: { label: 'Last 30 days', value: 'last30Days' },
      dateOptions: [
        { label: 'Last 30 days', value: 'last30Days' },
        { label: 'This month', value: 'thisMonth' },
        { label: 'This quarter', value: 'thisQuarter' },
        { label: 'This year', value: 'thisYear' },
        { label: 'Last month', value: 'lastMonth' },
        { label: 'Last quarter', value: 'lastQuarter' },
        { label: 'Last year', value: 'lastYear' },
        { label: 'Custom', value: 'custom' }
      ],

      filterAvgRevenueByPhysican: 'Last 30 days',
      filterPatientEncouterBreakdown: 'Last 30 days',
      filterPatientEncouterBreakdownByDays: 'Last 30 days',
      filterRevenueByClinic: 'Last 30 days',
      filterRevenueOPvsCHC: 'Last 30 days',
      filterPatientPayerBreakdownBySpeciliaty: 'Last 30 days',
      hospitalCodeRevenueByClinic: ['CLINIC1', 'CLINIC2', 'CLINIC4', 'CLINIC3'],

      category: ['All', 'Cost', 'Revenue', 'Volume', 'Doctor', 'Nurse', 'EBITDA'],
      serviceType: ['All', 'Lab', 'Full', 'MRI'],
      filterCategory: 'All',
      filterServiceType: 'All',
      filteredFinancialOverview: []
    }
  },
  computed: {
    ...mapState('chart', [
      'avgRevenueByPhysican',
      'patientEncouterBreakdown',
      'patientEncouterBreakdownByDays',
      'revenueByClinic',
      'patientPayerBreakdownBySpeciliaty'
    ]),

    disabledStartDatesFrom () {
      return { from: new Date() }
    },

    disabledEndDatesTo () {
      return { to: new Date(this.startDate) }
    },

    disabledThisTimeTo () {
      return { to: new Date(this.lastTime) }
    }

    
  },
  async mounted () {
    await this.submitFilter(this.typeDate.value)
    //if (this.financialOverview.length) this.submitFinancialOverview()

    //this.submitFiltersRevenue2Years()
  },
  watch: {
    privacy () {
      if (this.privacy) {
        this.$vs.notify({
          title: 'Privacy',
          text: 'See your financial info by turning privacy off.',
          color: 'success',
          time: 8000,
          position: 'bottom-center'
        })
      }
    }
  },
  created () {},
  methods: {
    ...mapActions('chart', [
      'fetchAvgRevenueByPhysican',
      'fetchPatientEncouterBreakdown',
      'fetchRevenueByClinic',
      'fetchPatientPayerBreakdownBySpeciliaty'
    ]),

    getDatesRange (type) {
      const _this = this

      function last30Days () {
        const startDate = _this.$moment().subtract(30, 'days')
        const endDate = _this.$moment()

        return { startDate, endDate }
      }

      function thisMonth () {
        const startDate = _this.$moment().startOf('month')
        const endDate = _this.$moment().endOf('month')

        return { startDate, endDate }
      }

      function thisQuarter () {
        const startDate = _this
          .$moment()
          .quarter(_this.$moment().quarter())
          .startOf('quarter')
        const endDate = _this
          .$moment()
          .quarter(_this.$moment().quarter())
          .endOf('quarter')

        return { startDate, endDate }
      }

      function thisYear () {
        const startDate = _this.$moment().startOf('year')
        const endDate = _this.$moment().endOf('year')

        return { startDate, endDate }
      }

      function lastMonth () {
        const startDate = _this
          .$moment()
          .subtract(1, 'months')
          .startOf('month')
        const endDate = _this.$moment(startDate).endOf('month')

        return { startDate, endDate }
      }

      function lastQuarter () {
        const startDate = _this
          .$moment()
          .subtract(1, 'quarter')
          .startOf('quarter')
        const endDate = _this
          .$moment()
          .subtract(1, 'quarter')
          .endOf('quarter')

        return { startDate, endDate }
      }

      function lastYear () {
        const startDate = _this
          .$moment()
          .subtract(1, 'year')
          .startOf('year')
        const endDate = _this
          .$moment()
          .subtract(1, 'year')
          .endOf('year')

        return { startDate, endDate }
      }

      function custom () {
        return { startDate: _this.startDate, endDate: _this.endDate }
      }

      const dates = {
        last30Days,
        thisMonth,
        thisQuarter,
        thisYear,
        lastMonth,
        lastQuarter,
        lastYear,
        custom
      }

      return dates[type]()
    },
    async submitFilter (type) {
      const dateRange = this.getDatesRange(type)
      const startDay = this.$moment(dateRange.startDate).date()
      const startMonth = this.$moment(dateRange.startDate).month() + 1
      const startYear = this.$moment(dateRange.startDate).year()

      const endDay = this.$moment(dateRange.endDate).date()
      const endMonth = this.$moment(dateRange.endDate).month() + 1
      const endYear = this.$moment(dateRange.endDate).year()

      const p1 = this.fetchAvgRevenueByPhysican({
        endDay,
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        specialitys: this.specialitys,
        startDay,
        startMonth,
        startYear
      }).catch(err => {
        console.error(err)
      })

      const p2 = this.fetchPatientEncouterBreakdown({
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        startMonth,
        startYear
      }).catch(err => {
        console.error(err)
      })


      const p4 = this.fetchRevenueByClinic({
        endDay,
        endMonth,
        endYear,
        hospitalCodes: this.hospitalCodeRevenueByClinic,
        startDay,
        startMonth,
        startYear
      })
        // .then(res => {
        //   console.log(res)
        // })
        .catch(err => {
          console.error(err)
        })

      const p7 = this.fetchPatientPayerBreakdownBySpeciliaty({
        endDay,
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        specialitys: this.specialitys,
        startDay,
        startMonth,
        startYear
      }).catch(err => {
        console.error(err)
      })

      await Promise.all([p1, p2, p4, p7])
    },
    getAvgRevenueByPhysican (type) {
      this.filterAvgRevenueByPhysican = this.dateOptions.find(
        x => x.value === type
      ).label

      const dateRange = this.getDatesRange(type)
      const startDay = this.$moment(dateRange.startDate).date()
      const startMonth = this.$moment(dateRange.startDate).month() + 1
      const startYear = this.$moment(dateRange.startDate).year()

      const endDay = this.$moment(dateRange.endDate).date()
      const endMonth = this.$moment(dateRange.endDate).month() + 1
      const endYear = this.$moment(dateRange.endDate).year()

      const payload = {
        endDay,
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        specialitys: this.specialitys,
        startDay,
        startMonth,
        startYear
      }

      this.fetchAvgRevenueByPhysican(payload)
        // .then(res => {
        //   console.log(res)
        // })
        .catch(err => {
          console.error(err)
        })
    },
    getPatientEncouterBreakdown (type) {
      this.filterPatientEncouterBreakdown = this.dateOptions.find(
        x => x.value === type
      ).label

      const dateRange = this.getDatesRange(type)
      const startMonth = this.$moment(dateRange.startDate).month() + 1
      const startYear = this.$moment(dateRange.startDate).year()

      const endMonth = this.$moment(dateRange.endDate).month() + 1
      const endYear = this.$moment(dateRange.endDate).year()

      const payload = {
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        startMonth,
        startYear
      }

      this.fetchPatientEncouterBreakdown(payload)
        // .then(res => {
        //   console.log(res)
        // })
        .catch(err => {
          console.error(err)
        })
    },
    getRevenueByClinic (type) {
      this.filterRevenueByClinic = this.dateOptions.find(
        x => x.value === type
      ).label

      const dateRange = this.getDatesRange(type)
      const startDay = this.$moment(dateRange.startDate).date()
      const startMonth = this.$moment(dateRange.startDate).month() + 1
      const startYear = this.$moment(dateRange.startDate).year()

      const endDay = this.$moment(dateRange.endDate).date()
      const endMonth = this.$moment(dateRange.endDate).month() + 1
      const endYear = this.$moment(dateRange.endDate).year()

      this.fetchRevenueByClinic({
        endDay,
        endMonth,
        endYear,
        hospitalCodes: this.hospitalCodeRevenueByClinic,
        startDay,
        startMonth,
        startYear
      })
        // .then(res => {
        //   console.log(res)
        // })
        .catch(err => {
          console.error(err)
        })
    },
    getPatientPayerBreakdownBySpeciliaty (type) {
      this.filterPatientPayerBreakdownBySpeciliaty = this.dateOptions.find(
        x => x.value === type
      ).label

      const dateRange = this.getDatesRange(type)
      const startDay = this.$moment(dateRange.startDate).date()
      const startMonth = this.$moment(dateRange.startDate).month() + 1
      const startYear = this.$moment(dateRange.startDate).year()

      const endDay = this.$moment(dateRange.endDate).date()
      const endMonth = this.$moment(dateRange.endDate).month() + 1
      const endYear = this.$moment(dateRange.endDate).year()

      const payload = {
        endDay,
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        specialitys: this.specialitys,
        startDay,
        startMonth,
        startYear
      }

      this.fetchPatientPayerBreakdownBySpeciliaty(payload).catch(err => {
        console.error(err)
      })
    }
  }
}
</script>

<style lang="scss">
.vs-table--tbody-table {
  min-width: 0;
}
</style>
